import { numberOrStringToNumberSchema } from '@shared/schema/utils'
import { z } from 'zod'

export const GetAllSiteSchemaResponse = z.array(
  z.object({
    id: numberOrStringToNumberSchema,
    name: z.string(),
    address: z.string(),
    city: z.string(),
    country: z.string(),
    postcode: z.string().nullable(),
    equipmentCount: numberOrStringToNumberSchema.nullable(),
    buildingCount: numberOrStringToNumberSchema.nullable(),
    photoSiteId: z.string().nullable(),
    supervisionUrl: z.string().nullable(),
  }),
)

export type GetAllSiteResponse = z.infer<typeof GetAllSiteSchemaResponse>
